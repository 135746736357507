.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 4%;
}
.sig {
  width: 100%;
  height: 100px !important;

  overflow-wrap: break-word;
  overflow-y: scroll !important;
}
.bid {
  width: 100%;
  overflow-wrap: break-word;
  height: 100px !important;
  overflow-y: scroll !important;
}
.selling {
  width: 100%;

  border: 1px solid grey;
  padding: 50px;
}
.bidding {
  width: 100%;

  border: 1px solid grey;
  padding: 50px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
